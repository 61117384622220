<template>
  <div id="section6">
    <div class="fondoGrisClaro" id="woocommerce">
      <b-container fluid>
        <b-container class="text-right">
          <br>
          <h1>
            {{title}}
          </h1>
          <br>
          <div>
            <b-img :src="img"/>
          </div>
          <br>
          <p class="text-justify pb-5">
            <span>{{$t("text1")}}</span> 
          </p>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "text1": "Disponible bajo petición."
  },
  "es":{
    "text1": "Disponible bajo petición."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      title: "Plugin de WooCommerce"
      , img: require("@/assets/images/pay/partners/woocommerce.png")
    }
  },
  methods: {
    
  },
  computed: {
    
  }
};
</script>
