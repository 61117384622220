<template>
  <div id="section2">
    <div class="fondoAzul" >
      <b-container fluid>
        <b-container >
          <br />
          <h1  class="text-left">
            {{$t("title")}}
          </h1>
          <br />
          <b-row>
            <b-col md="3" sm="12" v-for="(item, index) in btnNames" 
              class="text-center pb-5" :key="index">
              <div>
                <b-img :src="btns[index].img"/>
              </div>
              <br>
              <a v-scroll-to="btns[index].url" class="btn buttonWhite1">
                {{item}}
              </a>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "title": "Plugins integrables con las principales plataformas y herramientas de ECommerce."
    , "btnNames": [
      "TIENDA COMPLETA"
      , "PLUGIN DE PRESTASHOP"
      , "PLUGIN DE MAGNETO"
      , "PLUGIN DE WOOCOMMERCE"
    ]
  },
  "es":{
    "title": "Plugins integrables con las principales plataformas y herramientas de ECommerce."
    , "btnNames": [
      "TIENDA COMPLETA"
      , "PLUGIN DE PRESTASHOP"
      , "PLUGIN DE MAGNETO"
      , "PLUGIN DE WOOCOMMERCE"
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      btns: [
        {
          img: require("@/assets/images/pay/partners/palbin_blanco.png")
          , url: "#palbin"
        },
        {
          img: require("@/assets/images/pay/partners/prestashop_blanco.png")
          , url: "#prestashop"
        },
        {
          img: require("@/assets/images/pay/partners/magento_blanco.png")
          , url: "#magento"
        },
        {
          img: require("@/assets/images/pay/partners/woocommerce_blanco.png")
          , url: "#woocommerce"
        }
      ]
    };
  },
  computed: {
    btnNames() {
      return this.$t("btnNames");
    }
  }
};
</script>
