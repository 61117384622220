<template>
  <div id="section5">
    <div class="fondoWhite" id="magento">
      <b-container fluid>
        <b-container>
          <br>
          <h1>
            {{title}}
          </h1>
          <br>
          <div>
            <b-img :src="img"/>
          </div>
          <br>
          <p class="text-justify pb-1" v-for="(item, index) in text" :key="index">
            {{item}}
          </p>
          <br>
          <div>
            <a :href="url" class="btn buttonAzul">
              {{$t("btn")}}
            </a>
          </div>
          <br>
          <p class="text-justify">
            <span>{{$t("text1")}}</span> 
            <span><a :href="'mailto:'+mail">{{mail}}</a></span>
          </p>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "text": [
      "Presentamos la solución Hosted Payments by Snap*. Con esta solución de UniversalPay podrás aceptar el pago con tarjeta de manera rápida y segura."
      , "Activa la solución en tu tienda Magento y podrás mostrar un formulario de pago tanto como redirección como solución integrada en iFrame."
    ]
    , "btn": "VER PLUGIN"
    , "text1": "Para cualquier duda o comentario escribe a "
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      title: "Plugin de Magento"
      , img: require("@/assets/images/pay/partners/magento.png")
      , url: "https://marketplace.magento.com/"
      , mail: "partners@aicad.es"
    }
  },
  methods: {
    
  },
  computed: {
    text() {
      return this.$t("text");
    }
  }
};
</script>
