<template>
  <div id="section3">
    <div class="fondoWhite" id="palbin">
      <b-container fluid>
        <b-container>
          <br>
          <h1>
            {{title}}
          </h1>
          <br>
          <div>
            <b-img :src="img"/>
          </div>
          <br>
          <p class="text-justify">
            {{$t("text")}}
          </p>
          <br>
          <div>
            <a :href="url" class="btn buttonAzul">
              {{$t("btn")}}
            </a>
          </div>
          <br>
          <p class="text-justify">
            <span>{{$t("text1")}}</span> 
            <span><a :href="'mailto:'+mail">{{mail}}</a></span>
          </p>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "text": "Palbin es una Plataforma de comercio online en la nube que permite vender en Internet, Facebook e Instagram con todas las herramientas ya incluidas. Como método de pago de tarjetas de débito y crédito, ya tiene plenamente integrado la gestión de pago de UniversalPay con todas sus funcionalidades, además de gestión de logística integrada, herramientas de marketing digital y gestión de clientes. Además incluyen consultoría especializada y soporte técnico para guiar a todos los clientes por el proceso de venta online."
    , "btn": "TIENDA COMPLETA"
    , "text1": "Para cualquier duda o comentario escribe a "
  },
  "es":{
    "text": "Palbin es una Plataforma de comercio online en la nube que permite vender en Internet, Facebook e Instagram con todas las herramientas ya incluidas. Como método de pago de tarjetas de débito y crédito, ya tiene plenamente integrado la gestión de pago de UniversalPay con todas sus funcionalidades, además de gestión de logística integrada, herramientas de marketing digital y gestión de clientes. Además incluyen consultoría especializada y soporte técnico para guiar a todos los clientes por el proceso de venta online."
    , "btn": "TIENDA COMPLETA"
    , "text1": "Para cualquier duda o comentario escribe a "
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      title: "Palbin"
      , img: require("@/assets/images/pay/partners/palbin.png")
      , url: "https://www.palbin.com"
      , mail: "partners@aicad.es"
    }
  },
  methods: {
    
  },
  computed: {
    
  }
};
</script>
